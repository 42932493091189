import { useAuthInfo } from '@propelauth/react';
import type { Permission } from '~/ts-types';

import { useMetadata } from './use-metadata';

const useAuthorization = () => {
  const { metadata } = useMetadata();
  const orgId = metadata?.currentUser.orgId;
  const authInfo = useAuthInfo();

  const hasPermission = (permission: Permission) => {
    if (!orgId) {
      return false;
    }
    return !!authInfo.accessHelper?.hasPermission(orgId, permission);
  };

  return {
    hasPermission,
  } as const;
};

export { useAuthorization };
